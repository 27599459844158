<template>
  <div class="my-8">
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col>
              <label for="to">{{ $t("To") }}</label>
              <v-autocomplete
                name="to"
                :items="parentsStudents"
                v-model="msgData.students"
                :rules="
                  msgData.classes.length == 0
                    ? [checkMultiSelect(msgData.students)]
                    : []
                "
                item-text="name"
                item-value="id"
                multiple
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="Class">{{ $t("To Class") }}</label>
              <v-autocomplete
                name="Class"
                :items="classes"
                v-model="msgData.classes"
                :rules="
                  msgData.students.length == 0
                    ? [checkMultiSelect(msgData.classes)]
                    : []
                "
                item-text="name"
                item-value="id"
                multiple
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="msg_type">{{ $t("Message Type") }}</label>
              <v-radio-group
                v-model="msgData.type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio :label="$t('SMS')" value="sms"></v-radio>
                <v-radio :label="$t('Email')" value="email"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="msgData.type && msgData.type == 'email'">
            <v-col>
              <label for="subject">{{ $t("Email Subject") }}</label>
              <v-text-field
                v-model="msgData.subject"
                name="subject"
                :rules="
                  msgData.type == 'email' ? [validationRules.required] : []
                "
                solo
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="msgData.type">
            <v-col>
              <label for="body">{{ $t("Message") }}</label>
              <v-textarea
                v-model="msgData.body"
                :rules="[validationRules.required]"
                name="body"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center my-4">
              <v-btn class="main-btn submit-btn" @click.prevent="saveMessage">{{
                $t("Save")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="snackbar = false"
    ></snackComponent>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";
import snackComponent from "@/components/snackComponent";

export default {
  name: "sendCustomEmailOrSms",
  mixins: [validationMixin],
  components: { snackComponent },

  data() {
    return {
      valid: false,
      snackbar: false,
      snackColor: "green",
      snackMsg: "",
      msgType: "",
      parentsStudents: [],
      classes: [],
      msgData: {
        students: [],
        classes: [],
        type: "",
        subject: "",
        body: "",
      },
    };
  },
  watch: {
    "msgData.students": {
      handler() {
        if (this.msgData.students.length > 0) {
          this.msgData.classes = [];
        }
      },
      deep: true,
    },
    "msgData.classes": {
      handler() {
        if (this.msgData.classes.length > 0) {
          this.msgData.students = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    getUserData() {
      this.$http
        .get(this.getApiUrl + "/notifications/allUsersInSystem", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.parentsStudents = response.data.data.students;
            this.classes = response.data.data.clasess;
          }
        });
    },
    saveMessage() {
      if (this.valid) {
        this.$http
          .post(this.getApiUrl + "/notifications/sendMessages", this.msgData, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.snackbar = true;
              this.snackMsg = this.$i18n.t("Message Sent Successfully");
              setTimeout(() => {
                this.snackbar = false;
              }, 2000);
              this.msgData = {
                students: [],
                classes: [],
                type: "",
                subject: "",
                body: "",
              };
              this.resetValidation();
            }
          });
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/_modals.scss";
</style>
